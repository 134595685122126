import { SHOW_MAGIC } from "./actions";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_MAGIC:
      return {
        enabled: action.payload,
      };
  }
  return state;
};

export default reducer;
