import React from "react";
import { map } from "lodash";
import MainPage from "./components/Pages/MainPage";
import MusicPage from "./components/Pages/MusicPage";
import DesignPage from "./components/Pages/DesignPage";
import "./app.css";
import CodePage from "./components/Pages/CodePage/CodePage";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useLocation } from "react-router";
import { css } from "@emotion/css";

function App() {
  return (
    <Router>
      <Menu />
      <Switch>
        <Route path="/design">
          <DesignPage />
        </Route>
        <Route path="/music">
          <MusicPage />
        </Route>
        <Route path="/code">
          <CodePage />
        </Route>
        <Route path="/" exact>
          <MainPage />
        </Route>
      </Switch>
    </Router>
  );
}

function Menu() {
  const location = useLocation();
  const MENU_ITEMS = [
    {
      name: "Code",
      path: "/code",
    },
    {
      name: "Make Music",
      path: "/music",
    },
    {
      name: "Design",
      path: "/design",
    },
  ];
  return (
    <Ul>
      <Logotype current={location.pathname === "/"}>
        <Link to="/">
          SONER{" "}
          <span
            className={css`
              text-decoration: underline;
              font-weight: 100;
              color: #333;
            `}
          >
            CAN
          </span>
        </Link>
      </Logotype>
      {map(MENU_ITEMS, (i) => (
        <Li current={location.pathname === i.path} key={i.name}>
          <Link to={i.path}>{i.name}</Link>
        </Li>
      ))}
    </Ul>
  );
}
interface LiProps {
  current: boolean;
}

const Logotype = styled.li<LiProps>`
  font-size: 1.5em;
  padding: 5px 5px 5px 0;
  margin-right: 10px;
  color: black;
  a {
    background-color: white;

    color: black;
    text-decoration: none;
    display: block;
  }
`;

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 10px;
  display: flex;
  font-family: "Bebas Neue", cursive;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
`;

const Li = styled.li<LiProps>`
  :not(:nth-child(1)) {
    margin-left: 3px;
  }
  font-size: 1.1em;
  a {
    line-height: 30px;
    padding: 8px 20px 5px 20px;
    background-color: ${(props) => (props?.current ? "black" : "white")};
    border: solid 1px black;
    color: ${(props) => (props?.current ? "white" : "black")};
    text-decoration: none;
    display: block;

    &:hover {
      color: white;
      background-color: black;
    }
  }
`;

export default App;
