import React from "react";
import Faderr from "../Faderr";
import { Container, Header, Text } from "../../shared/styles";
function DesignPage() {
  return (
    <Faderr>
      <Header bg="#FED5CA">DESIGN</Header>
      <Container>
        <Text>
          Soner can <b>design</b>. Soner has been designing for different media
          and applications since more than a decade.
        </Text>
      </Container>
    </Faderr>
  );
}

export default DesignPage;
