import React from "react";
import { useSpring, animated } from "react-spring";

function Faderr({ children }) {
  const style = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 100,
  });
  return <animated.div style={style}>{children}</animated.div>;
}

export default Faderr;
