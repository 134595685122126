import { css } from "@emotion/css";
import styled from "@emotion/styled";
import React from "react";
import { Container, Header, PageCenter, Text } from "../../shared/styles";
import Face from "./../../assets/face.jpg";
import Faderr from "../Faderr";
import { useSpring, animated } from "react-spring";

function MainPage() {
  const imageStyle = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 200,
  });
  return (
    <Faderr>
      <Header bg="#F5F5F5">ABOUT</Header>
      <PageCenter>
        <div className={mainContainer}>
          <animated.img
            src={Face}
            style={imageStyle}
            className={css`
              width: 200px;
              /* margin-top: 20px; */
              object-fit: contain;
            `}
          />
          <MainText>
            <Text>
              Soner is mostly a <b>software developer</b>; sometimes a{" "}
              <b>designer</b> and ocassionally a <b>music producer</b>. He used
              to professionally edit videos, perform voice acting and produce
              radio programs in the past, too.
            </Text>
          </MainText>
        </div>
      </PageCenter>
    </Faderr>
  );
}

export default MainPage;

const MainText = styled(Container)`
  margin-left: -20px;
`;

const mainContainer = css`
  display: flex;
  flex-flow: row nowrap;
`;
