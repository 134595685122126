import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import MagicSagas from "./redux/Magic/sagas";

import logger from "redux-logger";
import magic from "./redux/Magic/reducer";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  combineReducers({ magic }),
  applyMiddleware(logger, sagaMiddleware)
);
sagaMiddleware.run(MagicSagas);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
