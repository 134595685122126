import React from "react";
import Faderr from "../Faderr";
import { Container, Header, PageCenter, Text } from "../../shared/styles";
function MusicPage() {
  return (
    <Faderr>
      <Header bg="#B4DEE8">MUSIC</Header>
      <PageCenter>
        <Container>
          <Text>
            Soner can <b>make music</b>. Music has always been a part of Soner's
            life but he's spending quite some time on music production in the
            recent years.
          </Text>
        </Container>
      </PageCenter>
    </Faderr>
  );
}

export default MusicPage;
