import React from "react";
import Faderr from "../../Faderr";
import { Container, Header, Text } from "../../../shared/styles";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_MAGIC, toggleMagic } from "../../../redux/Magic/actions";
function CodePage() {
  const dispatch = useDispatch();
  const magicEnabled = useSelector((state: any) => state?.magic?.enabled);
  return (
    <Faderr>
      <Header bg="#422F2A">
        <span style={{ color: "white" }}>CODE</span>
      </Header>
      <Container>
        <Text>
          Soner can <b>code</b>. He has a total of 10+ years of software
          development experience in different technologies and diciplines
          including JavaScript (React, Node.js), Ruby (Ruby on Rails), Go, iOS
          development, game development in Unity. In the recent years, he is
          primarily focused on frontend development of large b2b applications,
          especially with React/Redux stack.
        </Text>
        {/* <DestroyButton onClick={(e) => dispatch(toggleMagic(!magicEnabled))}>
          {magicEnabled ? "Revert" : "Do some magic"}
        </DestroyButton> */}
      </Container>
    </Faderr>
  );
}

export default CodePage;

const DestroyButton = styled.button`
  width: 100px;
  height: 100px;
  background-color: red;
  border: solid 3px black;
  border-radius: 50%;
  color: white;
`;
