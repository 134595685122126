import { all, take } from "redux-saga/effects";
import { SHOW_MAGIC } from "./actions";

function* showMagic() {
  while (true) {
    const action = yield take(SHOW_MAGIC);
  }
}

export default function* rootSaga() {
  yield all([showMagic()]);
}
