import styled from "@emotion/styled";

interface ContainerProps {
  bg?: string;
  padding?: string;
}

export const Container = styled.div<ContainerProps>`
  background-color: ${(props) => props.bg ?? "white"};
  padding: ${(props) => props.padding ?? "20px"};
`;

export const Text = styled.p`
  font-size: 1.2em;
  line-height: 1.7em;
`;

export const Header = styled(Container)`
  padding: ${(props) => props.padding ?? "40px 20px 10px 20px"};
  border-bottom: solid 1px #666;
  font-size: 1.7em;
  font-weight: bold;
  font-family: "Saira Condensed", sans-serif;
`;

export const PageCenter = styled.div`
  display: flex;
  /* @media (min-width: 850px) {
    justify-content: center;
  } */
`;
